import React, { useState, useEffect, useMemo, useRef } from "react";
import "./index.css";

// take an input background color and return a Post component with that background color
const Section = () => (
  <div className="post" style={{ backgroundSize: "cover" }}></div>
);

const ScrollCarousel = ({ sections, setCurrentSection }) => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(
    document.documentElement.clientHeight
  );
  const carouselRef = useRef(null);

  useEffect(() => {
    const setHeight = () => {
      if (carouselRef.current) {
        setViewportHeight(carouselRef.current.offsetHeight);
      }
    };

    window.addEventListener("resize", setHeight);

    // Initial setHeight call
    setHeight();

    return () => window.removeEventListener("resize", setHeight);
  }, []);

  useEffect(() => {
    const handleScroll = (event) => {
      const scrollPosition = event.target.scrollTop;
      const sectionIndex = Math.round(scrollPosition / viewportHeight);

      if (sectionIndex !== currentSectionIndex) {
        setCurrentSectionIndex(sectionIndex);
        console.log("Displayed section index:", sectionIndex);
        setCurrentSection(sectionIndex); // update the global state variable
        console.log("setCurrentSection:", sectionIndex);
      }
    };

    const currentSection = carouselRef.current; // capture the current value

    if (currentSection) {
      currentSection.addEventListener("scroll", handleScroll, {
        passive: true,
      });

      return () => {
        currentSection.removeEventListener("scroll", handleScroll);
      };
    }
  }, [
    currentSectionIndex,
    setCurrentSectionIndex,
    setCurrentSection,
    viewportHeight,
  ]); // added setGlobalGifIndex to dependencies

  return (
    <div className="carousel-container absolute top-0 left-0" ref={carouselRef}>
      {Array.from(Array(sections), (_, index) => (
        <Section key={index} />
      ))}
    </div>
  );
};

const TitleContent = ({ getContentClass, gif }) => (
  <div
    id="background"
    className={`absolute left-0 w-full h-screen transition-opacity duration-1000 ease-in-out text-4xl text-black text-center ${getContentClass(
      "title"
    )}`}
    style={{
      backgroundImage: `url(${gif})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    }}
  ></div>
);

const BodyContent = ({ getContentClass, content, gif }) => (
  <div
    id="background"
    className={`absolute top-0 left-0 w-full h-screen transition-opacity duration-1000 ease-in-out text-4xl text-black text-center ${getContentClass(
      "body"
    )}`}
  >
    <div
      className="absolute -top-9 left-0 w-full"
      id="gif"
      style={{
        backgroundImage: `url(${gif})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    ></div>
    <div className="absolute left-0 w-full h-full">
      {content.textType === "title" && (
        <div
          className="p-16 pb-32 text-3xl text-white font-semibold leading-10"
          id="titletext"
        >
          {content.text}
        </div>
      )}
      {content.textType === "body" && (
        <div className="px-10  text-base text-white font-medium" id="bodytext">
          {content.text}
        </div>
      )}
      {content.button && (
        <div className="p-10 pb-32 mx-auto">
          <a href={content.button} target="_blank" rel="noreferrer">
            <button className="bg-white text-black text-2xl py-3 px-4 rounded-md">
              Explore More
            </button>
          </a>
        </div>
      )}
    </div>
  </div>
);

const ContactContent = ({ getContentClass }) => (
  <div
    id="background"
    className={`absolute top-0 left-0 w-full h-screen transition-opacity duration-1000 ease-in-out text-center ${getContentClass(
      "contact"
    )}`}
  >
    <div
      className="
    p-20 pb-5 text-white font-semibold text-3xl leading-10 absolute top-32 w-full
    "
    >
      Explore the Mystical Ceramics
    </div>
    <div className="px-24 text-white font-light absolute w-full" id="creations">
      Ceramic creations by Jihyun Kim
    </div>
    {/* <button className="text-white text-2xl py-2.5 px-4 rounded-xl border-white border-2 font-medium">
      <a
        href="https://www.jihyunkimceramic.com/"
        target="_blank"
        rel="noreferrer"
      >
        VISIT WEBSITE
      </a>
    </button> */}
  </div>
);

const Content = ({ content, gifUrls }) => {
  // console.log("Content:", content, "backgroundGif", content.backgroundGif);
  // console.log("Gif:", gifUrls, gifUrls[content.backgroundGif]);
  const [currentContent, setCurrentContent] = useState(0);

  useEffect(() => {
    setCurrentContent(content);
  }, [content]);

  const getContentClass = (type) => {
    // if (currentContent && currentContent.type === type) {
    //   return "fade-in";
    // }
    // return "fade-out";
  };

  console.log(" content:", content);

  switch (content.type) {
    case "title":
      return (
        <TitleContent
          getContentClass={getContentClass}
          gif={gifUrls[currentContent.backgroundGif]}
        />
      );
    case "body":
      return (
        <BodyContent
          getContentClass={getContentClass}
          content={currentContent}
          gif={gifUrls[currentContent.backgroundGif]}
        />
      );
    case "contact":
      return <ContactContent getContentClass={getContentClass} />;
    default:
      return null;
  }
};

const App = () => {
  const gifUrls = useMemo(
    () => [
      "./gifs/Title.gif",
      "./gifs/SaltyFairy.gif",
      "./gifs/FingerNail.gif",
      "./gifs/GuidanceLichen.gif",
    ],
    []
  );

  const content = [
    {
      type: "title",
      backgroundGif: 0,
      text: null,
      textType: null,
      button: null,
    },
    {
      type: "body",
      backgroundGif: 1,
      text: "Salty Fairy Ring",
      textType: "title",
      button: null,
    },
    {
      type: "body",
      backgroundGif: 1,
      text: "Salty Fairy Ring series merges the Korean superstition of using salt jars for the protection of the house with the mystical lore of fairy rings.",
      textType: "body",
      button: null,
    },
    {
      type: "body",
      backgroundGif: 1,
      text: "The collection of salt containers embodies a sense of wonder and enchantment, blending cultural beliefs with natural elements.",
      textType: "body",
      button: null,
    },
    {
      type: "body",
      backgroundGif: 2,
      text: "Fingernail Fungi",
      textType: "title",
      button: null,
    },
    {
      type: "body",
      backgroundGif: 2,
      text: "Inspired by superstition from Korean belief, cutting one's nails at night may attract mice who will become a doppelganger.",
      textType: "body",
      button: null,
    },
    {
      type: "body",
      backgroundGif: 2,
      text: "Combining this story with the poisonous Red coral fungi, which resembles a hand, I designed a container that not only safeguards the nails from the greedy mice.",
      textType: "body",
      button: null,
    },
    {
      type: "body",
      backgroundGif: 3,
      text: "Guidance Lichen",
      textType: "title",
      button: null,
    },
    {
      type: "body",
      backgroundGif: 3,
      text: "The 'Guidance Lichen' candle holder draws inspiration from a Korean ancestral ritual.",
      textType: "body",
      button: null,
    },
    {
      type: "body",
      backgroundGif: 3,
      text: "In this tradition, families express gratitude and invite their ancestors' spirits into their homes, using candles as a guide.",
      textType: "body",
      button: null,
    },
    {
      type: "body",
      backgroundGif: 3,
      text: "The piece combines this story with lichen's healing properties and ecological significance, resulting in a meaningful and visually captivating piece.",
      textType: "body",
      button: null,
    },
    {
      type: "contact",
      backgroundGif: null,
      text: null,
      textType: null,
      button: null,
    },
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [currentSection, setCurrentSection] = useState(0); // added new state variables

  useEffect(() => {
    let loadedImages = 0;
    gifUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        loadedImages++;
        if (loadedImages === gifUrls.length) {
          setIsLoading(false);
        }
      };
      img.onerror = () => {
        console.error(`Failed to load image at ${url}`);
      };
    });
  }, [gifUrls]);

  return (
    <div>
      {isLoading ? (
        <div>
          <img src="./gifs/loading.gif" alt="Loading..." />
        </div>
      ) : (
        <div className="relative">
          <Content content={content[currentSection]} gifUrls={gifUrls} />
          <ScrollCarousel
            sections={content.length}
            setCurrentSection={setCurrentSection}
          />
          {currentSection !== 11 ? null : (
            <div
              className="absolute w-full flex flex-col justify-end"
              id="button"
            >
              <a
                href="https://www.jihyunkimceramic.com/"
                target="_blank"
                rel="noreferrer"
                className="w-full flex justify-center"
              >
                <button className="text-white text-xl py-2.5 px-4 rounded-xl border-white border-2 font-medium mx-auto">
                  VISIT WEBSITE
                </button>
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default App;
